export const ENTRY_GROUP_ENTRY_FIELD_TOTP_ALGORITHM_SHA1 = 'sha1';
export const ENTRY_GROUP_ENTRY_FIELD_TOTP_ALGORITHM_SHA256 = 'sha256';
export const ENTRY_GROUP_ENTRY_FIELD_TOTP_ALGORITHM_SHA512 = 'sha512';
export const ENTRY_GROUP_ENTRY_FIELD_TOTP_ALGORITHM_DEFAULT = ENTRY_GROUP_ENTRY_FIELD_TOTP_ALGORITHM_SHA1;

const ENTRY_GROUP_ENTRY_FIELD_TOTP_ALGORITHMS_TO_TOTPJS = {};

ENTRY_GROUP_ENTRY_FIELD_TOTP_ALGORITHMS_TO_TOTPJS[ENTRY_GROUP_ENTRY_FIELD_TOTP_ALGORITHM_SHA1] = 'SHA-1';
ENTRY_GROUP_ENTRY_FIELD_TOTP_ALGORITHMS_TO_TOTPJS[ENTRY_GROUP_ENTRY_FIELD_TOTP_ALGORITHM_SHA256] = 'SHA-256';
ENTRY_GROUP_ENTRY_FIELD_TOTP_ALGORITHMS_TO_TOTPJS[ENTRY_GROUP_ENTRY_FIELD_TOTP_ALGORITHM_SHA512] = 'SHA-512';

export {ENTRY_GROUP_ENTRY_FIELD_TOTP_ALGORITHMS_TO_TOTPJS};
